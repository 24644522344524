import { motion } from 'framer-motion';
import Link from 'next/link';
import React from 'react';
import { Box, Container, H2, Image, P, Section } from 'react-components';

import styled from 'styled-components';
import bitwala from './assets/bitwala.svg';
import daura from './assets/daura.svg';
import hek from './assets/hek.svg';
import muuvr from './assets/muuvr.svg';
import nfg from './assets/nfg.svg';
import oaf from './assets/oaf.svg';
import pesko from './assets/pesko.svg';

const StyledImage = styled(Image)`
  transition: filter 0.2s ease-in-out;
  filter: grayscale(1) contrast(0.3) brightness(1.5);

  &:hover {
    filter: grayscale(0) contrast(1) brightness(1);
  }
`;

export const Partners: React.FunctionComponent = () => {
  const partners = [
    {
      logo: {
        url: muuvr.src,
        alt: 'Muuvr logo',
      },
      url: 'https://muuvr.io/',
    },

    {
      logo: {
        url: bitwala.src,
        alt: 'Bitwala logo',
      },
      url: 'https://www.bitwala.com/',
    },

    {
      logo: {
        url: daura.src,
        alt: 'Daura logo',
      },
      url: 'https://daura.ch/',
    },
    {
      logo: {
        url: oaf.src,
        alt: 'Openair Frauenfeld logo',
      },
      url: 'https://www.openair-frauenfeld.ch/',
    },
    {
      logo: {
        url: pesko.src,
        alt: 'Pesko logo',
      },
      url: 'https://www.pesko.ch/',
    },
    {
      logo: {
        url: nfg.src,
        alt: 'NFG logo',
      },
      url: 'https://www.nfg.wine/',
    },
    {
      logo: {
        url: hek.src,
        alt: 'HEK logo',
      },
      url: 'https://www.hek.ch/',
    },
  ];

  return (
    <Section bg="blacks.black100">
      <Container py={{ _: '3rem', sm: '6rem' }}>
        <H2 mb={5} textAlign="center">
          Companies of all sizes use our wallet infrastructure!
        </H2>
        <Box
          mt={{ _: 4, sm: '6rem' }}
          display="grid"
          gridTemplateColumns={{ _: 'repeat(2, 1fr)', sm: 'repeat(4, 1fr)' }}
          gridRowGap={{ _: 5, sm: 4 }}
          gridColumnGap="10%"
        >
          {partners.map(({ logo, url }, i: number) => (
            <Box display="flex" justifyContent="center" key={i}>
              <Link href={url} target="_blank">
                <StyledImage
                  as={motion.img}
                  whileHover={{
                    scale: 1.05,
                  }}
                  src={logo.url}
                  width="100%"
                  alt={logo.alt}
                  maxWidth={{ _: 'min(70%, 15rem)', sm: '80%' }}
                  objectFit="contain"
                />
              </Link>
            </Box>
          ))}
          <Box display="flex" justifyContent="center">
            <P color="gray" textAlign="center">
              ... and many more
            </P>
          </Box>
        </Box>
      </Container>
    </Section>
  );
};
