import { useRouter } from 'next/router';
import React, { PropsWithChildren, useEffect } from 'react';
import { Box, Footer, Header } from 'react-components';
import { useRootStyle } from 'shared/contexts/root-style-context';

import { TermlyStyles } from 'shared/styles/termly-styles';

export const LandingPageLayout: React.FunctionComponent<PropsWithChildren> = ({ children }) => {
  const router = useRouter();

  const { setStyle, removeStyle } = useRootStyle();

  useEffect(() => {
    setStyle('overflow', 'auto');
    setStyle('height', 'auto');
    setStyle('position', 'relative');

    return () => {
      removeStyle('overflow');
      removeStyle('height');
      removeStyle('position');
    };
  }, [setStyle, removeStyle]);

  return (
    <>
      <TermlyStyles />
      <Header
        pages={[
          { to: '/', text: 'Home', active: router.asPath === '/' },
          {
            to: 'https://docs.pierwallet.com/',
            text: 'Docs',
          },
          { to: '/about-us', text: 'About Us', active: router.asPath === '/about-us' },
          { to: '/blog', text: 'Blog', active: router.asPath === '/blog' },
          { to: '/help', text: 'Help', active: router.asPath === '/help' },
        ]}
        signInRoute="/login"
        signUpRoute="/signup"
        isLoggedIn={false}
        appRoute="https://dashboard.pierwallet.com"
      />
      <Box as="main" pt={{ _: 0, sm: '5.5rem' }}>
        {children}
      </Box>
      <Footer privacyPolicyPath="/privacy" termsPath="/terms" />
    </>
  );
};
