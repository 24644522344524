import { getAnalytics, logEvent, setUserId, setUserProperties } from 'firebase/analytics';
import { MetaPixel } from './meta-pixel';

let firebaseAnalytics: ReturnType<typeof getAnalytics>;
if (typeof window !== 'undefined') {
  // const app = firebase.initializeApp(FIREBASE_CONFIG_PRODUCTION);
  // firebaseAnalytics = getAnalytics(app);
}

const track = async (event: string, params?: { [k: string]: string }) => {
  MetaPixel.track({ name: event, params });
  logEvent(firebaseAnalytics, event, params ?? {});
};

const identify = async (userId: string) => {
  return setUserId(firebaseAnalytics, userId);
};

const setUserProperty = async ({ name, value }: { name: string; value: string }) => {
  setUserProperties(firebaseAnalytics, { name, value });
};

export const Analytics = {
  track,
  identify,
  setUserProperty,
};
