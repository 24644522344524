import React from 'react';

import {
  Box,
  Button,
  ButtonSize,
  ButtonVariant,
  Container,
  H2,
  Image,
  Lead,
  Section,
} from 'react-components';
import { motion } from 'framer-motion';
import Link from 'next/link';

export const NexterGenerationWallet: React.FunctionComponent = ({}) => {
  return (
    <Section bg="black" py={{ _: '3rem', sm: 6 }}>
      <Container
        display="flex"
        flexDirection={{ _: 'column-reverse', sm: 'row' }}
        alignItems="center"
      >
        <Box display="flex" flexDirection="column" justifyContent="center">
          <H2
            as={motion.h2}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 'all' }}
            variants={{
              visible: { opacity: 1, x: 0 },
              hidden: { opacity: 0, x: -20 },
            }}
            transition={{ type: 'spring', stiffness: 30 }}
            my={0}
            color="white"
            textAlign={{ _: 'start', sm: 'center' }}
          >
            Next generation Wallet as a Service
          </H2>
          <Lead
            color="white"
            as={motion.p}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 'all' }}
            variants={{
              visible: { opacity: 1, x: 0 },
              hidden: { opacity: 0, x: 20 },
            }}
            transition={{ type: 'spring', stiffness: 30, delay: 0.2 }}
            mt={{ _: '1.5rem', sm: 4 }}
            mb={4}
            textAlign={{ _: 'start', sm: 'center' }}
          >
            Integrating our wallet infrastructure into your product is as simple as it can be: Use
            our SDK or connect directly to our API. Benefit from an easy documentation at your
            fingertips and support from our developers, when needed.
          </Lead>
          <Box
            as={motion.div}
            initial="hidden"
            variants={{
              visible: { opacity: 1, y: 0 },
              hidden: { opacity: 0, y: 20 },
            }}
            whileInView="visible"
            transition={{ delay: 0.75 }}
            display="flex"
            gridGap={{ _: 1, sm: '1.5rem' }}
            flexWrap="wrap"
            justifyContent={{ _: 'start', sm: 'center' }}
          >
            <Link href="https://docs.pierwallet.com/" target="_blank">
              <Button variant={ButtonVariant.DEFAULT_INVERSE} size={ButtonSize.LARGE}>
                Read the docs
              </Button>
            </Link>
          </Box>
        </Box>
      </Container>
    </Section>
  );
};
