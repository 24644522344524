import { Box } from 'react-components';

import { Engagement } from './sections/Engagement';
import { Hero } from './sections/Hero';
import { NexterGenerationWallet } from './sections/NexterGenerationWallet';
import { NoApp } from './sections/NoApp';
import { OurCommunity } from './sections/OurCommunity';
import { Partners } from './sections/Partners';
import { Reinvent } from './sections/Reinvent';
import { SafeAndEasy } from './sections/SafeAndEasy';

export const LandingPage: React.FunctionComponent = () => {
  return (
    <Box>
      <Hero />
      <Partners />
      <Engagement />
      <NexterGenerationWallet />
      <SafeAndEasy />
      <Reinvent />
      <NoApp />

      {/* <CommunityQuotes /> */}
      <OurCommunity />
    </Box>
  );
};
