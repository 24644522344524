import React from 'react';

import {
  Box,
  Container,
  H1,
  Image,
  Lead,
  AnimatedSection,
  Button,
  ButtonVariant,
  ButtonSize,
} from 'react-components';
import { motion } from 'framer-motion';

import swissness from './assets/swissness.svg';
import homepageDoorHandle from './assets/homepage-door-handle.svg';
import Link from 'next/link';
import { contactEmail } from 'shared/globals';

export const Hero: React.FunctionComponent = ({}) => {
  return (
    <AnimatedSection colors={['blacks.black50']} pb={{ _: 4, sm: 6 }} pt={{ _: '6rem', sm: 0 }}>
      <Container display="flex" flexDirection={{ _: 'column', sm: 'row' }} alignItems="center">
        <Box flex={4} gridGap={{ _: 1, sm: '1.5rem' }}>
          <H1
            as={motion.h1}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 'all' }}
            variants={{
              visible: { opacity: 1, y: 0 },
              hidden: { opacity: 0, y: 20 },
            }}
            transition={{ delay: 0.25 }}
            mb={{ _: 0, sm: '3rem' }}
            mt="6rem"
          >
            Wallet infrastructure - made in Switzerland
          </H1>
          <Lead
            color="black"
            my={{ _: '1.5rem', sm: '3rem' }}
            as={motion.p}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 'all' }}
            variants={{
              visible: { opacity: 1, y: 0 },
              hidden: { opacity: 0, y: 20 },
            }}
            transition={{ delay: 0.5 }}
          >
            pier is a wallet-as-a-service platform that makes your app blockchain ready with a few
            lines of code.
          </Lead>
          <Box
            as={motion.div}
            initial="hidden"
            variants={{
              visible: { opacity: 1, y: 0 },
              hidden: { opacity: 0, y: 20 },
            }}
            whileInView="visible"
            transition={{ delay: 0.75 }}
            display="flex"
            gridGap={{ _: 1, sm: '1.5rem' }}
            flexWrap="wrap"
          >
            <Link
              href="https://outlook.office365.com/owa/calendar/pierdemo@pierwallet.com/bookings/"
              target="_blank"
            >
              <Button variant={ButtonVariant.OUTLINED} size={ButtonSize.LARGE}>
                Get in touch
              </Button>
            </Link>
          </Box>
          <Box display="flex" alignItems="center" gridGap={2} mt={4}>
            <Image src={swissness.src} alt="swiss flag" width="1.5rem" height="1.5rem" />
            <Box as="span">Based & developed in Switzerland</Box>
          </Box>{' '}
        </Box>
        <Box flex={2} mt={{ _: 5, sm: 6 }}>
          <Image
            src={homepageDoorHandle.src}
            alt="pier on a mobile phone"
            width="130%"
            display={{ _: 'none', sm: 'block' }}
            px={{ _: 5, sm: 3 }}
          />
        </Box>
      </Container>
    </AnimatedSection>
  );
};
