import styled from 'styled-components';
import Experiment from './assets/experiment.svg';
import Gear from './assets/gear.svg';
import Key from './assets/key.svg';
import Star from './assets/star-three.svg';
import { BulletSection } from '../components/bullet-section';
import { IconCardWithoutIcon } from '../components/icon-card-without-icon';

const List = styled.ul`
  padding-left: 15px;
  margin: 0;
`;

export const Engagement: React.FunctionComponent = ({}) => {
  return (
    <BulletSection
      title="Full capabilities of Web3 without compromising on simplicity"
      bg="blacks.black50"
      body=""
    >
      <IconCardWithoutIcon imageSrc={Star.src} title="Seamless experience">
        <List>
          <li>Flexible user onboarding</li>
          <li>No user-payed gas fees</li>
          <li>Track your platform usage in our dashboard</li>
        </List>
      </IconCardWithoutIcon>
      <IconCardWithoutIcon imageSrc={Key.src} title="Own your keys">
        <List>
          <li>True self-custody</li>
          <li>Upholding of Web3 principles</li>
        </List>
      </IconCardWithoutIcon>
      <IconCardWithoutIcon imageSrc={Experiment.src} title="Simple integration">
        <List>
          <li>API integration</li>
          <li>Documentation and support available</li>
        </List>
      </IconCardWithoutIcon>
      <IconCardWithoutIcon imageSrc={Gear.src} title="Tailored security">
        <List>
          <li>No seed-phrase</li>
          <li>Social recovery</li>
          <li>Authenticator recovery</li>
        </List>{' '}
      </IconCardWithoutIcon>
    </BulletSection>
  );
};
