import { LandingPage } from 'modules/landing';
import { ReactElement } from 'react';
import { LandingPageLayout } from 'shared/layouts/landing-page-layout';
import { NextPageWithLayout } from 'shared/util-types/next-types';

const Index: NextPageWithLayout = () => {
  return <LandingPage />;
};

Index.getLayout = function getLayout(page: ReactElement) {
  return <LandingPageLayout>{page}</LandingPageLayout>;
};

export default Index;
