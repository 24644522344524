import React from 'react';
import PaperPlane from './assets/paper-plane.svg';

import { motion } from 'framer-motion';
import Image from 'next/image';
import {
  Box,
  Button,
  ButtonSize,
  ButtonVariant,
  Container,
  H2,
  IoLogoDiscord,
  IoLogoInstagram,
  IoLogoLinkedin,
  IoLogoTwitter,
  Lead,
  Section,
  useBreakpoint,
} from 'react-components';

import { trackOutboundLinkClick } from 'modules/landing/sections/track-outbound-link-click';
import {
  contactEmail,
  discordLink,
  instagramLink,
  linkedInLink,
  twitterLink,
} from 'shared/globals';
import styled from 'styled-components';

const StyledLink = styled.a`
  text-decoration: underline;
`;

export const OurCommunity: React.FunctionComponent = ({}) => {
  const isSmUp = useBreakpoint('sm');

  return (
    <Section bg={{ _: 'white', sm: 'success.success50' }} py={{ _: '3rem', sm: '5.375rem' }}>
      <Container textAlign={{ _: 'start', sm: 'center' }}>
        <Box display="flex" justifyContent="center">
          <Image src={PaperPlane.src} width={200} height={200} alt="" />
          {/* <PaperPlane
            variant={PaperPlaneVariant.WARNING}
            width={isSmUp ? '21rem' : 'min(17.6rem, 100%)'}
            height={isSmUp ? '21rem' : '12.5rem'}
          /> */}
        </Box>
        <H2
          color="black"
          mt={0}
          mb={{ _: 3, sm: 4 }}
          as={motion.h2}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 'all' }}
          variants={{
            visible: { opacity: 1, y: 0 },
            hidden: { opacity: 0, y: 20 },
          }}
          transition={{ type: 'spring', stiffness: 30, delay: 0 }}
        >
          Like what you see?
        </H2>
        <Lead
          color="black"
          mb={{ _: 4, sm: '3rem' }}
          as={motion.p}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 'all' }}
          variants={{
            visible: { opacity: 1, y: 0 },
            hidden: { opacity: 0, y: 20 },
          }}
          transition={{ type: 'spring', stiffness: 30, delay: 0.2 }}
        >
          Drop us a{' '}
          <StyledLink
            onClick={() =>
              window.open(
                `mailto:${contactEmail}?subject=${encodeURIComponent(
                  'How can pier help my business?'
                )}`
              )
            }
          >
            mail
          </StyledLink>{' '}
          or join our community on Discord .
        </Lead>
        <Box display="flex" justifyContent={{ _: 'flex-start', sm: 'center' }} gridGap="1rem">
          <Button
            px={3}
            variant={ButtonVariant.DEFAULT}
            size={ButtonSize.LARGE}
            onClick={trackOutboundLinkClick('landingpage_community_twitter_clicked', twitterLink)}
          >
            <IoLogoTwitter size="1.5rem" />
          </Button>
          <Button
            px={3}
            variant={ButtonVariant.DEFAULT}
            size={ButtonSize.LARGE}
            onClick={trackOutboundLinkClick('landingpage_community_linkedin_clicked', linkedInLink)}
          >
            <IoLogoLinkedin size="1.5rem" />
          </Button>
          <Button
            px={3}
            variant={ButtonVariant.DEFAULT}
            size={ButtonSize.LARGE}
            onClick={trackOutboundLinkClick('landingpage_community_discord_clicked', discordLink)}
          >
            <IoLogoDiscord size="1.5rem" />
          </Button>
          <Button
            px={3}
            variant={ButtonVariant.DEFAULT}
            size={ButtonSize.LARGE}
            onClick={trackOutboundLinkClick(
              'landingpage_community_instagram_clicked',
              instagramLink
            )}
          >
            <IoLogoInstagram size="1.5rem" />
          </Button>
        </Box>
      </Container>
    </Section>
  );
};
