import { Analytics } from 'shared/analytics/analytics';

const openLink = (link: string) => window.open(link, '_blank')?.focus();

export function trackOutboundLinkClick(
  events: string | string[],
  link: string
): () => Promise<void> {
  return () =>
    new Promise<void>((resolve) => {
      (typeof events === 'string' ? [events] : events).forEach((x) => Analytics.track(x));

      openLink(link);
      resolve();
    });
}
