import React from 'react';

import {
  Box,
  Button,
  ButtonSize,
  ButtonVariant,
  Container,
  H2,
  Image,
  Lead,
  Section,
} from 'react-components';
import { motion } from 'framer-motion';
import Satellite from './assets/satellite.svg';

import Link from 'next/link';

export const SafeAndEasy: React.FunctionComponent = ({}) => {
  return (
    <Section bg="success.success50" py={{ _: '3rem', sm: 6 }}>
      <Container
        display="flex"
        flexDirection={{ _: 'column-reverse', sm: 'row' }}
        alignItems="center"
      >
        <Box flex={2} mt={0}>
          <Image
            src={Satellite.src}
            alt="pier on a mobile"
            width="100%"
            px={{ _: 0, sm: 5 }}
            display={{ _: 'none', sm: 'block' }}
          />
        </Box>
        <Box flex={3} display="flex" flexDirection="column" justifyContent="center">
          <H2
            as={motion.h2}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 'all' }}
            variants={{
              visible: { opacity: 1, x: 0 },
              hidden: { opacity: 0, x: -20 },
            }}
            transition={{ type: 'spring', stiffness: 30 }}
            my={0}
          >
            Web3 with the user experience of Web2
          </H2>
          <Lead
            color="black"
            as={motion.p}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 'all' }}
            variants={{
              visible: { opacity: 1, x: 0 },
              hidden: { opacity: 0, x: 20 },
            }}
            transition={{ type: 'spring', stiffness: 30, delay: 0.2 }}
            mt={{ _: '1.5rem', sm: 4 }}
            mb={4}
          >
            ith account abstraction (ERC-4337), your users will experience Web3 without even
            noticing. Say goodbye to blockchain complexity.
          </Lead>

          <Box
            as={motion.div}
            initial="hidden"
            variants={{
              visible: { opacity: 1, y: 0 },
              hidden: { opacity: 0, y: 20 },
            }}
            whileInView="visible"
            transition={{ delay: 0.75 }}
            display={{ _: 'flex', sm: 'block' }}
            gridGap={{ _: 1, sm: '1.5rem' }}
            flexWrap="wrap"
            justifyContent={{ _: 'start', sm: 'center' }}
          >
            <Link href="/about-us" passHref={true} legacyBehavior>
              <Button variant={ButtonVariant.DEFAULT} size={ButtonSize.LARGE} as="a">
                Discover success stories
              </Button>
            </Link>
          </Box>
        </Box>
      </Container>
    </Section>
  );
};
