// @ts-ignore
const Meta: () => facebook.Pixel.Event | null = () => (typeof fbq === 'function' ? fbq : null);

export enum MetaStandardEvent {
  AddPaymentInfo = 'AddPaymentInfo',
  AddToCart = 'AddToCart',
  AddToWishlist = 'AddToWishlist',
  CompleteRegistration = 'CompleteRegistration',
  Contact = 'Contact',
  CustomizeProduct = 'CustomizeProduct',
  Donate = 'Donate',
  FindLocation = 'FindLocation',
  InitiateCheckout = 'InitiateCheckout',
  Lead = 'Lead',
  Purchase = 'Purchase',
  Schedule = 'Schedule',
  Search = 'Search',
  StartTrial = 'StartTrial',
  SubmitApplication = 'SubmitApplication',
  Subscribe = 'Subscribe',
  ViewContent = 'ViewContent',
}

async function track({ name, params }: { name: string; params?: { [k: string]: string } }) {
  if ((Object.values(MetaStandardEvent) as string[]).includes(name)) {
    Meta()?.('track', name, params ?? {});
    return;
  }

  Meta()?.('trackCustom', name, params ?? {});
}

function page({ name }: { name: string }) {
  Meta()?.('track', 'PageView', { URL: name });
}

export const MetaPixel = {
  track,
  page,
};
