import { Box, Container, FloatingSnitch, H1Dot5, List, P, Section } from 'react-components';

export const Reinvent: React.FunctionComponent = ({}) => {
  return (
    <Section py={{ _: '3rem', sm: '6rem' }} bg="blacks.black50">
      <Container>
        <Box
          display="grid"
          gridTemplateColumns={{ _: '1fr', sm: 'repeat(2, minmax(0, 1fr))' }}
          gridTemplateAreas={{ _: "'c' 'a' 'b'", sm: "'a a' 'b c'" }}
        >
          <Box gridArea="a">
            <H1Dot5>Account Abstraction makes Web3 simple and reliable</H1Dot5>
          </Box>
          <Box gridArea="b">
            <List.Root mt="3rem">
              <List.Item>
                <P fontSize={{ _: 3, sm: 6 }}>
                  <strong>social & authenticator app</strong> recovery remove the need for a{' '}
                  <em>seed phrase</em>
                </P>
              </List.Item>
              <List.Item>
                <P fontSize={{ _: 3, sm: 6 }}>
                  The <strong>paymaster</strong> removes the bad user experience with gas fees
                </P>
              </List.Item>
              <List.Item>
                <P fontSize={{ _: 3, sm: 6 }}>
                  <strong>Transaction batching & automation</strong> increases usability &
                  reliability
                </P>
              </List.Item>
              <List.Item>
                <P fontSize={{ _: 3, sm: 6 }}>
                  <strong>Multi Signature</strong> increases security and trust
                </P>
              </List.Item>
              <List.Item>
                <P fontSize={{ _: 3, sm: 6 }}>
                  <strong>MPC</strong> (multi party computation) allows biometric and social logins
                </P>
              </List.Item>
            </List.Root>
          </Box>

          <Box gridArea="c" display={{ _: 'none', sm: 'flex' }} justifyContent="center">
            <FloatingSnitch width="100%" color="blacks.black200" />
          </Box>
        </Box>
      </Container>
    </Section>
  );
};
