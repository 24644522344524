import { PropsWithChildren } from "react";
import { Box, Container, H1, P, Section, ThemeColor } from "react-components";

interface BulletSectionProps {
  title: string;
  body: string;
  bg: ThemeColor;
}

export const BulletSection: React.FunctionComponent<
  PropsWithChildren<BulletSectionProps>
> = ({ title, body, children, bg }) => {
  return (
    <Section pt={{ _: "3rem", sm: "6rem" }} pb="6rem" bg={bg}>
      <Container>
        <Box
          display="grid"
          gridTemplateColumns={{ _: "1fr", sm: "repeat(1, minmax(0, 1fr))" }}
        >
          <Box>
            <H1 fontSize={{ sm: "3rem" }} mb="1.5rem" textAlign={"center"}>
              {title}
            </H1>
          </Box>
          <Box>
            <P fontSize={4}>{body}</P>
          </Box>
        </Box>
        <Box
          display="grid"
          gridTemplateColumns={{ _: "1fr", sm: "repeat(4, minmax(0, 1fr))" }}
          gridGap={4}
          mt={{ _: "3rem", sm: "6rem" }}
        >
          {children}
        </Box>
      </Container>
    </Section>
  );
};
