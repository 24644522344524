import {
  Box,
  Button,
  ButtonVariant,
  IconType,
  useBreakpoint,
  useThemeColor,
} from 'react-components';
import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';

const StyledButton = styled(Button)`
  border-radius: 0.6125rem;
`;

export interface AppStoreButtonProps {
  textSmall: string;
  textLarge: string;
  onClick(): void;
  icon: IconType;
}

export const AppStoreButton: React.FunctionComponent<PropsWithChildren<AppStoreButtonProps>> = ({
  onClick,
  textSmall,
  textLarge,
  icon: Icon,
}) => {
  const white = useThemeColor('white');
  const isSmUp = useBreakpoint('sm');

  return (
    <StyledButton
      variant={ButtonVariant.DEFAULT}
      onClick={onClick}
      p={{ _: '0.75rem 0.75rem 0.75rem 0.5rem', sm: '0.75rem 2rem 0.75rem 2rem' }}
    >
      <Box as="span" display="flex">
        <Box
          as="span"
          flex="0"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          pr={1}
        >
          <Icon size={isSmUp ? '2.25rem' : '1.75rem'} color={white} />
        </Box>
        <Box as="span" flex="1" display="flex" flexDirection="column" alignItems="flex-start">
          <Box as="span" fontSize={0} color="white" fontWeight="400">
            {textSmall}
          </Box>
          <Box as="span" fontSize={{ _: 2, sm: 3 }} color="white" lineHeight="1">
            {textLarge}
          </Box>
        </Box>
      </Box>
    </StyledButton>
  );
};
