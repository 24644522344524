import Image from "next/image";
import React, { PropsWithChildren } from "react";
import { Box, H3 } from "react-components";

export interface IconCardProps {
  imageSrc: string;
  title: string;
}

export const IconCardWithoutIcon: React.FunctionComponent<
  PropsWithChildren<IconCardProps>
> = ({ imageSrc, title, children }) => {
  return (
    <Box p="1.5rem" border="1px solid" borderColor="black" borderRadius="1rem">
      <Box paddingLeft="2rem">
        <Box
          width="3.875rem"
          height="3.875rem"
          display="flex"
          justifyContent="center"
          alignItems="center"
          color="white"
        >
          <Image src={imageSrc} width={100} height={100} alt="" />
        </Box>
      </Box>
      <H3 fontSize={{ _: "2rem", sm: "2rem" }} mt={4}>
        {title}
      </H3>
      <Box mt={3} lineHeight="1.5">
        {children}
      </Box>
    </Box>
  );
};
