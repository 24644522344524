import React from 'react';

import { motion } from 'framer-motion';
import {
  Box,
  Container,
  H2,
  IoLogoApple,
  IoLogoGooglePlaystore,
  Lead,
  Section,
} from 'react-components';

import { nobankAppStoreLink, nobankPlayStoreLink } from 'shared/globals';
import { AppStoreButton } from '../components/app-store-button';
import { trackOutboundLinkClick } from './track-outbound-link-click';

export const NoApp: React.FunctionComponent = ({}) => {
  return (
    <Section bg="blacks.black100" py={{ _: '3rem', sm: 6 }}>
      <Container
        display="flex"
        flexDirection={{ _: 'column-reverse', sm: 'row' }}
        alignItems="center"
      >
        <Box display="flex" flexDirection="column" justifyContent="center">
          <H2
            as={motion.h2}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 'all' }}
            variants={{
              visible: { opacity: 1, x: 0 },
              hidden: { opacity: 0, x: -20 },
            }}
            transition={{ type: 'spring', stiffness: 30 }}
            my={0}
            color="black"
            textAlign={{ _: 'start', sm: 'center' }}
          >
            {`Don't have an app yet? Customize our wallet app to your needs.`}
          </H2>
          <Lead
            color="black"
            as={motion.p}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 'all' }}
            variants={{
              visible: { opacity: 1, x: 0 },
              hidden: { opacity: 0, x: 20 },
            }}
            transition={{ type: 'spring', stiffness: 30, delay: 0.2 }}
            mt={{ _: '1.5rem', sm: 4 }}
            mb={0}
            textAlign={{ _: 'start', sm: 'center' }}
          >
            With pier wallet, we have a ready-to-use app available for you. Customize your
            onboarding, easily distribute tokens and NFTs and add your own content.
          </Lead>

          <Box>
            <Lead
              color="black"
              as={motion.p}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, amount: 'all' }}
              variants={{
                visible: { opacity: 1, x: 0 },
                hidden: { opacity: 0, x: 20 },
              }}
              transition={{ type: 'spring', stiffness: 30, delay: 0.2 }}
              mt={{ _: '1rem', sm: 4 }}
              mb={4}
              textAlign={{ _: 'start', sm: 'center' }}
            >
              Check it out!
            </Lead>

            <Box
              as={motion.div}
              initial="hidden"
              variants={{
                visible: { opacity: 1, y: 0 },
                hidden: { opacity: 0, y: 20 },
              }}
              whileInView="visible"
              transition={{ delay: 0.75 }}
              display="flex"
              gridGap={{ _: 1, sm: '1.5rem' }}
              flexWrap="wrap"
              alignItems="center"
              justifyContent={{ _: 'start', sm: 'center' }}
            >
              <AppStoreButton
                icon={IoLogoApple}
                textSmall="Download on the"
                textLarge="App Store"
                onClick={trackOutboundLinkClick(
                  ['landingpage_app_store_link_clicked', 'download'],
                  nobankAppStoreLink
                )}
              />
              <AppStoreButton
                icon={IoLogoGooglePlaystore}
                textSmall="Get it on"
                textLarge="Google Play"
                onClick={trackOutboundLinkClick(
                  ['landingpage_play_store_link_clicked', 'download'],
                  nobankPlayStoreLink
                )}
              />
            </Box>
          </Box>
        </Box>
      </Container>
    </Section>
  );
};
